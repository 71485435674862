import { useFetchFanRequestsQuery } from 'api/fanRequests';
import ErrorPage from 'components/ErrorPage';
import FanRequestForm from 'components/FanRequestForm';
import Loader from 'components/Loader';
import getTypeFromFanRequest from 'helpers/getTypeFromFanRequest';
import routeFor from 'helpers/routeFor';
import routes from 'helpers/routes';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import FanRequest from 'types/FanRequest';
import FanRequestType from 'types/FanRequestType';
import FanRequestsSidebar from './components/FanRequestsSidebar';
import { FanRequestError, FanRequestsNoResults } from './screens';
import FanRequestsShow from './screens/FanRequestsShow';
import Onboarding from 'screens/Onboarding';

interface FanRequestsProps {
  renderNav: () => JSX.Element | null;
}

export default function FanRequests(props: FanRequestsProps) {
  const { renderNav } = props;
  const { id, type } = useParams<{ id?: string; type?: FanRequestType }>();
  const { t } = useTranslation();
  const {
    data: fanRequests,
    isFetching,
    isLoading,
    error,
  } = useFetchFanRequestsQuery(
    {},
    { refetchOnMountOrArgChange: true, skip: !type }
  );

  const fanRequestsByType = (fanRequests || []).reduce(
    (result, request) => {
      const type = getTypeFromFanRequest(request);
      return { ...result, [type]: [...result[type], request] };
    },
    {
      live: [] as FanRequest[],
      expired: [] as FanRequest[],
      draft: [] as FanRequest[],
    }
  );

  if (!type) return <Redirect to={routeFor(routes.requests.index, 'live')} />;

  if (error) return <ErrorPage />;

  const fanRequest = id
    ? fanRequestsByType[type]?.find((f) => f.id === parseInt(id, 10))
    : undefined;

  // Redirect to the fan request ID if it exists in a different type
  if (id && fanRequests && !fanRequest) {
    const foundRequest = fanRequests.find((f) => f.id === parseInt(id, 10));
    if (foundRequest) {
      return (
        <Redirect
          to={routeFor(
            routes.requests.index,
            getTypeFromFanRequest(foundRequest),
            id
          )}
        />
      );
    }
  }

  return (
    <>
      <Helmet title={t('fanRequests.pageTitle')} />
      <div className="flex flex-col">
        <div className="grid-col-296px-auto">
          <div>
            {renderNav && renderNav()}
            <FanRequestsSidebar
              type={type}
              fanRequestsByType={fanRequestsByType}
              isLoading={isFetching}
            />
          </div>
          <div>
            <div className="flex flex-col items-stretch w-full bg-white min-h-screen">
              {isLoading ? (
                <div className="pt-9">
                  <Loader />
                </div>
              ) : fanRequest ? (
                <Switch>
                  <Route path={routes.requests.edit}>
                    <FanRequestForm
                      fanRequest={fanRequest!}
                      context="edit"
                      key={fanRequest.id}
                    />
                  </Route>

                  <Route path={routes.requests.build}>
                    <FanRequestForm
                      fanRequest={fanRequest!}
                      context="builder"
                    />
                  </Route>

                  <Route path={routes.requests.show}>
                    <FanRequestsShow fanRequest={fanRequest} />
                  </Route>
                </Switch>
              ) : isFetching ? (
                <div className="pt-9">
                  <Loader />
                </div>
              ) : id && !fanRequest ? (
                <FanRequestError status={404} />
              ) : fanRequestsByType[type][0] ? (
                <Redirect
                  to={routeFor(
                    routes.requests.show,
                    type,
                    fanRequestsByType[type][0].id
                  )}
                />
              ) : (
                <FanRequestsNoResults type={type} />
              )}

              <Onboarding />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
